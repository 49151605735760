import { useEffect, useMemo, useState } from "react";
import { API } from "../API";
import { compareMonth } from "../compareMonth";

const type = {
  "2024_08": "2024_08",
};

function AttendanceEvent() {
  // 나의 출석 현황
  // developtment access token eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjo5OTYyOTk3ODg3LCJ1c2VyX25hbWUiOiJoZW8iLCJpYXQiOjE3MTM5MjQ4MjMsImV4cCI6MTc0NTQ4MjQyMywiaXNzIjoiUk9NQU5EIn0.ISfTXbfVt5BMIKK3RFubLvKY_RZoibTRBL4maWLqeEc
  // pro access token eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjo5OTcyMDI2NjgyLCJ1c2VyX25hbWUiOiJ0ZXN0ZXIiLCJpYXQiOjE3MTAzMjQzMzAsImV4cCI6MTc0MTg4MTkzMCwiaXNzIjoiUk9NQU5EIn0.YDHAPWUEgoEiq14lFi6Dy6g5EAa6mav38TLO_ohRaYg
  const params = new URLSearchParams(window.location.search);
  const accessToken = params.get("accessToken");
  // const accessToken =
  //   "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjo5OTYyOTk3ODg3LCJ1c2VyX25hbWUiOiJoZW8iLCJpYXQiOjE3MTM5MjQ4MjMsImV4cCI6MTc0NTQ4MjQyMywiaXNzIjoiUk9NQU5EIn0.ISfTXbfVt5BMIKK3RFubLvKY_RZoibTRBL4maWLqeEc";
  const env = params.get("env") || "production";
  // const env = params.get("env") || "development";

  const eventMonth = "08";
  const eventState = compareMonth(eventMonth);

  const [attendanceData, setAttendanceData] = useState();
  const { isTodayAttendance, attendanceCount, isCompleteAttendance } =
    attendanceData || {};

  useEffect(() => {
    const fetchAttendance = async () => {
      if (env != null && accessToken != null) {
        try {
          const { data } = await API.getAttendance(
            env,
            accessToken,
            type["2024_08"]
          );

          console.log(data);

          setTimeout(() => {
            setAttendanceData(data);
          }, 300);
        } catch (err) {
          console.log(err);
          sendError(err);
        }
      }
    };
    fetchAttendance();
  }, [env, accessToken]);

  function sendHeightValue() {
    const height = document.body.scrollHeight;
    const data = { type: "height", value: height };
    window?.ReactNativeWebView?.postMessage(JSON.stringify(data));

    window?.parent?.postMessage(
      {
        type: "IFRAME_HEIGHT",
        height: height,
      },
      "*"
    );
  }

  function sendDoAttendanceComplete() {
    window?.ReactNativeWebView?.postMessage(
      JSON.stringify({ type: "toast-info", value: "출석체크 완료!" })
    );
  }

  function sendError(error) {
    window?.ReactNativeWebView?.postMessage(
      JSON.stringify({
        type: "toast-error",
        value:
          error?.response?.data?.message ||
          error?.message ||
          "알 수 없는 오류가 발생했습니다.",
      })
    );
  }

  // receive message from app
  useEffect(() => {
    setTimeout(() => {
      sendHeightValue();
    }, 500);
  }, []);

  const renderCircles = (day, isActive) =>
    isActive ? (
      <img
        className="w-[52px] h-[52px] select-none"
        src="/images/icon/2024-08/2024-08-ico-complete.jpg"
        alt={`${day}일차 출석완료`}
      />
    ) : (
      <div className="w-[52px] h-[52px] bg-circle-inactive-2024-08 rounded-full flex items-center justify-center select-none">
        <span className="text-circle-inactive-text-2024-08 text-[13px]">
          {day}일차
        </span>
      </div>
    );

  const renderReward = (day, isActive) => (
    <img
      className="w-[52px] h-[52px]"
      src={`/images/icon/2024-08/2024-08-ico-${isActive ? "complete-" : ""}reward${day / 5}.jpg`}
      alt={`${day}일차 보상`}
    />
  );

  const renderAttendance = () =>
    Array.from({ length: 20 }, (_, i) => i + 1).map((day) =>
      day % 5 === 0
        ? renderReward(day, day <= attendanceCount)
        : renderCircles(day, day <= attendanceCount)
    );

  const conditionalActionButtonSource = useMemo(() => {
    let src = "";

    if (attendanceCount === 20) {
      src = "/images/button/2024-08/2024-08-btn-complete.jpg";
    } else if (isTodayAttendance) {
      src = "/images/button/2024-08/2024-08-btn-continue.jpg";
    } else {
      src = "/images/button/2024-08/2024-08-btn-action.jpg";
    }

    return src;
  }, [isTodayAttendance, attendanceCount]);

  async function onHandleAttendance() {
    if (!isTodayAttendance && !isCompleteAttendance) {
      try {
        const { data } = await API.attendance(env, accessToken, "2024_08");
        setAttendanceData(data);
        sendDoAttendanceComplete();
      } catch (err) {
        console.log(err);
        sendError(err);
      }
    }
  }

  // if (!accessToken) return null;

  return (
    <main
      className="w-full flex justify-center select-none"
      onDragStart={(e) => {
        e.preventDefault();
      }}
      style={{
        userSelect: "none",
      }}
    >
      <section className={"max-w-[400px] relative"}>
        <img
          src={"/images/background/2024-08/2024-08-main.jpg"}
          alt="Main Background"
          className={"w-full"}
        />
        <img
          src={"/images/background/2024-08/2024-08-main2.jpg"}
          alt="Main Background2"
          className={"w-full"}
        />
        <img
          className={"w-full"}
          src={"/images/background/2024-08/2024-08-bottom.jpg"}
          alt="Bottom Background"
        />
        {/* <img
          className={"w-full"}
          src={"/images/background/2024-08/2024-08-bottom2.jpg"}
          alt="Bottom Background2"
        /> */}
        {
          <div className={"top-[12.1%] z-10 absolute w-full"}>
            <div className={"flex flex-col"}>
              <span className={"text-center"}>
                나의 출석현황{" "}
                <span className={"text-current-n-2024-08 font-bold"}>
                  &nbsp;{attendanceCount || 0} 일차
                </span>
              </span>
              {/* 도장*/}
              <div
                className={
                  "w-full px-[38px] mt-[35px] grid grid-cols-5 grid-rows-4 gap-[10px] overflow-hidden"
                }
              >
                {renderAttendance()}
              </div>
              {/* action button */}
              {eventState && (
                <>
                  <div
                    className={"mt-[50px] flex justify-center px-[48px]"}
                    onClick={() => onHandleAttendance()}
                  >
                    <img
                      className={"w-[280px] h-[50px] cursor-pointer"}
                      src={conditionalActionButtonSource}
                      alt={"action button"}
                    />
                  </div>
                  <span className={"text-center mt-3 text-[11px] text-gray"}>
                    * 5, 10, 15, 20일 차에 출석하면 자동 응모됩니다.
                  </span>
                </>
              )}
            </div>
          </div>
        }
        {/* 본문 */}

        <a href={"icolorapp://action?what=page?to=mypageSetting/pushAlarm"}>
          <img
            src={"/images/button/btn-push.png"}
            alt={"push notification"}
            className={
              "absolute top-[87%] w-[360px] left-[30px] h-[80px] bg-[#6A9FFF] z-11 opacity-0"
            }
          />
        </a>
        <a href={"icolorapp://action?what=page?to=mypageSetting/infoManage"}>
          <img
            src={"/images/button/btn-push.png"}
            alt={"phone"}
            className={
              "absolute top-[94.8%] w-[360px] left-[30px] h-[80px] bg-[#6A9FFF] z-11 opacity-0"
            }
          />
        </a>
      </section>
    </main>
  );
}

export default AttendanceEvent;
